<template>
  <div>
    <div
      v-sticky="{ stickyTop: 0 }"
      class="has-background-white-ter"
      style="padding: 12px 0; display: none;">
      <div>
        <ElButton
          v-opacity-zero="selectedFilter.length === 0"
          size="small"
          type="text"
          class="is-size-7 has-text-danger"
          @click="selectedFilter = []">
          {{ $t('components.PackagesList.clear-all') }}
        </ElButton>
        <ElCheckboxGroup
          v-model="selectedFilter"
          class="lalala"
          size="small">
          <ElCheckboxButton
            v-for="it in keySelection"
            :key="it.key"
            :label="it.key">
            {{ it.key }}
            <!-- <span class="is-size-7">{{ it.val }}</span> -->
          </ElCheckboxButton>
        </ElCheckboxGroup>
      </div>
    </div>
    <EmptyState
      v-if="isLoading || packages.length === 0"
      :is-loading="isLoading"
      :message="$t('components.PackagesList.no-rooms')"
      class="has-text-centered" />
    <div v-if="!isLoading">
      <div
        v-for="(it, idx) in filtered"
        :key="it.token"
        :class="['availibility-item']">
        <slot
          :pckg="it"
          :idx="idx" />
      </div>
    </div>
    <div
      v-if="!isLoading && totalItems !== filtered.length"
      class="availibility-item has-text-centered more-rooms--action">
      <a
        v-loading="isFetchingMore"
        style="padding: 6px;"
        class="is-block"
        @click="handleMore(propertyCode)">
        {{ $t('components.PackagesList.show-more') }}
        <i
          class="el-icon-caret-bottom" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PackagesList',
  props: {
    packages: {
      type: Array,
      default () {
        return []
      }
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    searchId: {
      type: String,
      required: true,
      default: ''
    },
    propertyCode: {
      type: String,
      required: true,
      default: ''
    },
    totalItems: {
      type: Number,
      required: true,
      default: 3
    },
    isFetchingMore: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      selectedFilter: [],
      itemsPerLoad: 3,
      itemsLoaded: 3
    }
  },
  computed: {
    keySelection () {
      if (this.deviationKeys.included.length < 1) return this.arrayKeys
      if (this.arrayKeys.length < 9) return this.arrayKeys
      return this.deviationKeys.included
    },
    showing () {
      return this.filtered.slice(0, this.itemsLoaded) || []
    },
    filtered () {
      if (this.selectedFilter.length === 0) return this.packages
      return this.packages.filter(({ supplierDescription }) => this.selectedFilter.some((key) => {
        const regex = RegExp(key, 'gi')
        return regex.test(supplierDescription)
      })) || []
    },
    supplierDes () {
      if (this.packages.length < 1) return []
      return this.packages.map(ele => ele.supplierDescription.toLowerCase())
    },
    roomTypes () {
      const keys = {}
      if (this.supplierDes.length < 1) return keys
      let k = this.supplierDes
      k = this.supplierDes.map(ele => ele.replace(/-|\.\.\.|\(|\)/ig, ' '))
      k = k.map(ele => ele.replace(/\sroom[s]{0,1}(?=\b)|\swith(?=\s)|\s(?:one|size|two|view)(?=\b)|\sor(?=\s)|\sBEDROOM[s]{0,1}(?=\b)|\sBED[s]{0,1}(?=\b)/ig, ''))
      for (let idx = 0; idx < k.length; idx++) {
        let cache = k[idx]
        const matches = cache.match(/\bnon[\s|-]{0,1}\w+\b|\brun of house\b|\bDE[\s]{0,1}LUXE\b/ig)
        if (matches != null) {
          matches.forEach(it => {
            keys[it] = keys[it] == null ? 1 : keys[it] + 1
            cache = cache.replace(it, '')
          })
          k[idx] = cache
        }
      }
      for (let idx = 0; idx < k.length; idx++) {
        let ele = k[idx]
        const o = ele.split(' ')
        o.forEach(it => {
          if (it.length > 2) {
            keys[it] = keys[it] == null ? 1 : keys[it] + 1
          }
        })
      }
      return keys
    },
    arrayKeys () {
      if (this.roomTypes.length < 1) return []
      let arrayKeys = Object.keys(this.roomTypes)
      arrayKeys = arrayKeys.map(ele => ({
        key: ele,
        val: this.roomTypes[ele]
      }))
      arrayKeys.sort((a, b) => a.val - b.val)
      return arrayKeys
    },
    compansationKeys () {
      if (this.arrayKeys.length < 1) return []
      let compansationKeys = []
      this.arrayKeys.forEach((k, idx) => {
        let count = 0
        while (k.val > count) {
          compansationKeys.push(idx + 1)
          count++
        }
      })
      return compansationKeys
    },
    mean () {
      if (this.compansationKeys.length < 1) return 0
      return this.compansationKeys.reduce((pV, cV) => {
        return pV + cV
      }, 0) / this.compansationKeys.length
    },
    variance () {
      if (this.compansationKeys.length < 1) return 0
      return this.compansationKeys.reduce((pV, cV) => {
        return pV + Math.pow(cV - this.mean, 2)
      }, 0) / this.compansationKeys.length
    },
    standardDeviation () {
      if (this.variance === 0) return 0
      return Math.sqrt(this.variance)
    },
    deviationKeys () {
      const included = []
      const excluded = []
      if (this.standardDeviation === 0) {
        return {
          included,
          excluded
        }
      }

      const ceil = this.mean + (this.standardDeviation)
      const floor = this.mean - (this.standardDeviation)
      let k
      k = this.compansationKeys.map(idx => ({
        idx,
        absDeviation: idx - this.mean
      }))
      let count = 0
      while (k.length > count) {
        const cache = k[count]
        if (cache.idx < ceil && cache.idx > floor) {
          included[cache.idx - 1] = {
            val: this.arrayKeys[cache.idx - 1].val,
            absDeviation: cache.absDeviation,
            key: this.arrayKeys[cache.idx - 1].key
          }
        } else {
          excluded[cache.idx - 1] = {
            val: this.arrayKeys[cache.idx - 1].val,
            absDeviation: cache.absDeviation,
            key: this.arrayKeys[cache.idx - 1].key
          }
        }
        count++
      }
      return {
        included: included.filter(ele => ele),
        excluded: excluded.filter(ele => ele)
      }
    }
  },
  watch: {
    'searchId': {
      immediate: true,
      handler (nV, oV) {
        if (nV !== oV) this.reset()
      }
    },
    'propertyCode': {
      immediate: true,
      handler (nV, oV) {
        if (nV !== oV) this.reset()
      }
    },
    'selectedFilter': 'reset'
  },
  methods: {
    reset () {
      this.itemsLoaded = this.itemsPerLoad
    },
    handleMore (propertyCode) {
      this.$emit('more', { propertyCode })
      if (!this.isFetchingMore) {
        this.$emit('more', { propertyCode })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';

  .lalala {
    white-space: nowrap;
  }

  .availibility-item {

    background-color: $white;

    &:not(:last-child) {
      margin-bottom: $bleed * 4;
    }

    &.more-rooms--action {
      border: 2px solid #4D4D4D;
      border-radius: 5px;

      margin-top: $bleed * 3;

      & a {
        color: $color-4 !important;
        font-weight: bold;
        padding: 6px;

        & i {
          -webkit-animation: bounce 1s ease-in infinite alternate;
          animation: bounce 1s ease-in infinite alternate;
        }
      }
    }
  }

  .availibility-item ::v-deep .packages {
    background: transparent;
  }

  @-webkit-keyframes bounce {
    0% { transform: translateY(0); }
    100% { transform: translateY(-7px); }
  }

  @keyframes bounce {
    0% { transform: translateY(0); }
    100% { transform: translateY(-7px); }
  }
</style>
