<template>
  <div class="card-package">
    <div class="card-package-header">
      <div class="is-size-5 has-text-primary has-text-weight-bold">
        <el-checkbox v-model="checked" /> <span class="m-l-2x">{{ (pckg.supplierDescription || pckg.description) | truncate }}</span>
      </div>
    </div>
    <div class="card-package-content-wrapper">
      <div class="card-package-content">
        <div
          v-if="heroImage.url"
          class="attr">
          <div class="attr-key" />
          <div class="attr-val">
            <img
              :src="heroImage.url"
              class="pack-img">
          </div>
        </div>
        <div class="attr">
          <div
            class="attr-key"
            style="padding-left: 15px">
            {{ $t('components.Packages.label-title') }}
          </div>
          <div class="attr-val">
            <PackagesBenefits :pckg="pckg" />
          </div>
        </div>
        <div class="attr">
          <div class="attr-key">
            {{ $t('components.Packages.Occupancy') }}
          </div>
          <div class="attr-val">
            {{ occupancy }}
          </div>
        </div>
        <div class="attr">
          <div class="attr-key">
            Cancellation policy
          </div>
          <div
            v-if="freeCancellationBefore"
            class="attr-val">
            Free cancellation before {{ freeCancellationBefore | date }}
          </div>
          <div
            v-else
            class="attr-val">
            -
          </div>
        </div>
        <div class="attr">
          <div class="attr-key">
            Initial price for {{ nights }} night {{ roomCount }} room
          </div>
          <div class="attr-val">
            <el-tooltip
              placement="bottom"
              style="padding-right:5px;">
              <div slot="content">
                Note: These rates include {{ getAgentMinMarkupInPct(pckg) }}%<br>
                markup as set up by your account<br>
                admin.
              </div>
              <i class="el-icon-info has-text-secondary" />
            </el-tooltip>
            {{ adjustedDisplayRate | currencyObj({factionDigits:0}) }}
            <span class="markup">
              Agent earning: {{ pckg.agentMarkupAmount | currencyObj }}
            </span>
          </div>
        </div>
        <div class="attr">
          <div class="attr-key">
            Price with markup
          </div>
          <div class="attr-val">
            {{ adjustedDisplayRateMarkup | currency({factionDigits:0, currency:adjustedDisplayRate.currency}) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdjustmentBadge from '@/components/AdjustmentBadge'
import PriceAudit from '@/components/PriceAudit'
import mixPackages from '@/mixins/packages'
import PackagesBenefits from '@/components/PackagesBenefits'

export default {
  name: 'Packages',
  components: {
    [AdjustmentBadge.name]: AdjustmentBadge,
    [PriceAudit.name]: PriceAudit,
    PackagesBenefits
  },
  mixins: [mixPackages],
  props: {
    pckg: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    adultCount: {
      type: Number,
      required: true,
      default: 0
    },
    childrenCount: {
      type: Number,
      required: true,
      default: 0
    },
    nights: {
      type: Number,
      default: undefined
    },
    propertyCode: {
      type: String,
      required: true
    },
    idx: {
      type: Number,
      required: true
    },
    roomCount: {
      type: Number,
      default: undefined
    }
  },
  data () {
    return {
      // move to vuex store
      // inNightlyAvgRate: false // when it's false ,the price is all nights all rooms.  So when all night per room is displayed, do not switch it to false.
    }
  },
  computed: {
    occupancy () {
      return `${this.adultCount} Adult${this.adultCount > 1 ? 's' : ''}, ${this.childrenCount} child${this.childrenCount > 1 ? 'ren' : ''}`
    },
    nonRefundable () {
      return this.pckg.nonRefundable == null
    },
    marketRates () {
      return this.$store.state.inNightlyAvgRate ? this.pckg.unitMarketRates : this.pckg.marketRates
    },
    displayRate () {
      return this.$store.state.inNightlyAvgRate ? this.pckg.agentUnitDisplayRate : this.pckg.agentDisplayRate
    },
    adjustedDisplayRate () {
      return this.pckg.agentAdjustedDisplayRate
    },
    adjustedDisplayRateMarkup () {
      return this.$store.getters['quote/priceDiscount'](this.$store.getters['quote/priceMarkup'](this.adjustedDisplayRate.value))
    },
    checked: {
      get () {
        return this.pckg.checked
      },
      set (nV) {
        this.$store.commit('quote/CHECK_PACKAGE', { propertyCode: this.propertyCode, index: this.idx, checked: nV })
        if (this.$store.getters['quote/selectedPackages'](this.propertyCode) < 1) {
          this.$store.commit('quote/CHECK_PROPERTY', { propertyCode: this.propertyCode, checked: false })
        } else {
          this.$store.commit('quote/CHECK_PROPERTY', { propertyCode: this.propertyCode, checked: true })
        }
      }
    },
    freeCancellationBefore () {
      if (this.pckg.cancellationPolicy.freeCancellationBefore) return this.pckg.cancellationPolicy.freeCancellationBefore
      return ''
    }
  },
  methods: {
    getAgentMinMarkupInPct (pkg) {
      if (pkg && pkg.agentMinMarkupInPct) return pkg.agentMinMarkupInPct
      return 0
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '../../styles/bulma-variables';

  .card {
    &-package {
      border: $card-border;
      border-radius: $border-radius;
      background-color: $white;

      &-header {
        display: flex;
        padding: 8px 15px;
        align-items: center;
      }

      &-content-wrapper {
        display: flex;
      }

      &-content {
        display: flex;
        flex: 0 0 100%;
      }

      &-sub {
        display: flex;

        &:not(:last-child) {
          border-bottom: $card-border;
        }
      }

      .attr {
        flex: 1 1 auto;
        @include mobile {
          flex: 1 1 auto;
        }
      }

      .attr-action {
        text-align: right;
      }

      .attr-key {
        padding: $bleed $bleed*2;
        background-color: $tab-head-bg;
        height: 32px;
        overflow: hidden;
        color: $white;
        font-weight: bold;
      }

      .attr-val {
        padding: $bleed*3 $bleed*3;
      }

    }
  }

  .pack-img {
    width: 150px;
  }

  .markup {
    color: $grey;
    display: block;
    font-size: $size-7;
  }
</style>
