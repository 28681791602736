<template>
  <div>
    <hr class="m-b-2x m-t-4x">
    <div class="columns">
      <div class="column is-narrow">
        <el-checkbox v-model="checked" />
      </div>
      <div class="column">
        <!--summary start -->
        <div class="section-summary columns is-mobile">
          <div class="column">
            <StarRatings :ratings="property.starRating" />
            <h3 class="has-text-primary is-size-5 has-text-weight-bold has-line-height-1-125 m-b">
              {{ property.name }} <router-link
                :to="link"
                target="_blank"
                class="has-text-secondary is-size-6">
                See details &gt;
              </router-link>
            </h3>
            <PropertyAddress
              :params="$route.params"
              :query="$route.query"
              :map-link="false"
              :location="property && property.location"
              class="has-text-base" />
          </div>
          <div class="column is-narrow">
            <TrustYouRating
              :condensed="true"
              v-bind="property.trustYou"
              size="s" />
          </div>
        </div>
        <!--summary end -->
        <!--PackagesList start -->
        <PackagesList
          :search-id="$route.query.searchId"
          :property-code="propertyCode"
          :is-loading="false"
          :packages="availability.packages"
          :total-items="availability.pagination.totalItems"
          :items-loaded="itemsLoaded"
          :is-fetching-more="isFetchingMore"
          @more="handleMore">
          <Packages
            slot-scope="{ pckg, idx}"
            :nights="nights"
            :property-code="property.propertyCode"
            :pckg="pckg"
            :idx="idx"
            :adult-count="adultCount"
            :children-count="childrenCount"
            :room-count="roomCount" />
        </PackagesList>
        <!--PackagesList end -->
      </div>
    </div>
  </div>
</template>

<script>
import StarRatings from '../../components/StarRatings'
import PropertyAddress from '../../components/PropertyAddress'
import TrustYouRating from '../../components/TrustYou/TrustYouRating'
import PackagesList from './PackagesList'
import Packages from './Packages'
export default {
  name: 'Property',
  components: { Packages, PackagesList, TrustYouRating, PropertyAddress, StarRatings },
  props: {
    value: {
      type: Object,
      required: true
    },
    propertyCode: {
      type: String,
      required: true,
      default: ''
    },
    itemsLoaded: {
      type: Number,
      required: true,
      default: 3
    },
    isFetchingMore: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    property () {
      return this.value.property || {}
    },
    nights () {
      return this.value.nights
    },
    reviews () {
      return this.value.reviews
    },
    availability () {
      return this.value.availability
    },
    adultCount () {
      return this.availability.search.adult_count
    },
    roomCount () {
      return this.availability.search.room_count
    },
    childrenCount () {
      return Array.isArray(this.availability.search.children)
        ? this.availability.search.children.length : 0
    },
    checked: {
      get () {
        return this.value.checked
      },
      set (nV) {
        this.$store.commit('quote/CHECK_PROPERTY', { propertyCode: this.property.propertyCode, checked: nV })
      }
    },
    link () {
      return {
        name: 'property',
        params: {
          name: this.property.name
        },
        query: {
          ...this.$route.query,
          propertyCode: this.property.propertyCode
        }
      }
    }
  },
  methods: {
    handleMore (propertyCode) {
      this.$emit('more', propertyCode)
    }
  }
}
</script>

<style scoped>

</style>
