<template>
  <div class="discount-badge-wrapper">
    <!-- <div class="is-size-7 has-text-grey">Discount</div> -->
    <span
      v-if="adjustmentPct"
      class="discount-badge is-size-7">
      {{ $t('components.AdjustmentBadge.adjustment-pct',{'pct':pct}) }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'AdjustmentBadge',
  props: {
    adjustmentPct: [Number, undefined]
  },
  computed: {
    pct () {
      let pctFilter = this.$options.filters['pct']
      return pctFilter(Math.abs(this.adjustmentPct), { factionDigits: 0 })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../styles/bulma-variables";
  .discount-badge-wrapper {
    margin-bottom: $bleed;
  }
  .discount-badge {
    color: $white;
    background-color: $color-8;
    text-transform: uppercase;
    padding: 0 $bleed;
  }
</style>
