<template>
  <div class="dashboard-page">
    <div class="hero-my-bookings has-background-primary">
      <div class="container">
        <el-form
          ref="searchBookingsForm"
          :model="form"
          :rules="rules"
          class="columns is-multiline search-bookings-form"
          label-position="top">
          <div class="column is-12">
            <h2 class="is-size-3 has-text-weight-bold has-text-white">
              Generate Quote
            </h2>
            <p class="has-text-white quote-info">
              Create your quote by adding a name and a markup (optional),
              then select the rooms you would like to quote by ticking or
              unticking the box to the left of the room.
              Once done you can download the quote as a pdf and email to your client.
            </p>
          </div>
          <div class="column is-5">
            <el-form-item
              prop="customerName"
              label="Quote Name (event, guest or company)">
              <el-input
                v-model="customerName"
                placeholder="e.g. Alice Wong" />
            </el-form-item>
          </div>
          <div class="column">
            <div class="columns">
              <el-form-item
                label="Markup"
                class="column is-4 hide-border-radius-right"
                prop="markupType">
                <el-select
                  v-model="markupType">
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value" />
                </el-select>
              </el-form-item>
              <el-form-item
                class="column hide-border-radius-left"
                label=" "
                prop="markupAmount">
                <el-input
                  v-model="markupAmount"
                  v-number-input.float="{min:0,decimal:2}"
                  placeholder="Markup amount" />
              </el-form-item>
            </div>
          </div>
          <!--<div class="column">
            <div class="columns">
              <el-form-item
                label="Discount"
                class="column is-5 hide-border-radius-right"
                prop="currency">
                <el-select
                  v-model="discountType">
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"/>
                </el-select>
              </el-form-item>
              <el-form-item
                class="column is-7 hide-border-radius-left"
                label=" "
                prop="markupAmount">
                <el-input
                  v-number-input.float="{min:0,decimal:2}"
                  v-model="discountAmount"
                  placeholder="Discount amount"/>
              </el-form-item>
            </div>
            <p class="has-text-white"><strong class="has-text-white">Price after discount:</strong> {{ exampleDiscount | currency({currency:currency}) }}</p>
          </div>
          <div class="column is-2">
            <el-form-item
              label="Currency"
              prop="currency">
              <input-currency
                v-model="currency"
                style="width: 100%;"
                @input="currencyChange"/>
            </el-form-item>
          </div>-->
          <div class="column is-narrow has-text-right">
            <div style="height: 21px;" />
            <el-button
              type="primary"
              class="button-155"
              @click="download">
              Download Quote
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <el-alert
          v-if="fetchError"
          :description="fetchError"
          :closable="false"
          type="error" />
        <div v-if="hasItem">
          <h5 class="has-text-weight-bold is-size-6">
            {{ selectedHotelCount }} hotels selected.
          </h5>
          <template v-for="(item) in list">
            <Property
              v-if="!!item.property && item.property.propertyCode"
              :key="item.property.propertyCode"
              :value="item"
              :property-code="item.property.propertyCode"
              :items-loaded="list.length"
              :is-fetching-more="isFetchingMore"
              @more="handleMore" />
          </template>
        </div>
        <EmptyState
          v-else
          :is-loading="isFetching"
          message="No results found." />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters, mapActions } from 'vuex'
import Property from './Property'

export default {
  name: 'Quote',
  components: {
    Property
  },
  props: {
    query: Object
  },
  data () {
    return {
      typeOptions: [
        {
          label: 'Percentage',
          value: 'Percentage'
        },
        {
          label: 'Amount',
          value: 'Amount'
        }
      ],
      rules: {
        customerName: [
          { type: 'string', min: 2, message: 'Type at least 2 characters.', trigger: [ 'change', 'input' ] }
        ]
      }
    }
  },
  computed: {
    ...mapState('quote', ['list', 'isFetching', 'fetchError', 'examplePrice', 'isFetchingMore']),
    ...mapGetters('quote', ['hasItem', 'itemCount', 'selectedHotelCount', 'exampleMarkup', 'exampleDiscount']),
    customerName: {
      get () {
        return this.$store.state.quote.form.customerName
      },
      set (nV) {
        this.$store.commit('quote/SET_FORM', {
          customerName: nV
        })
      }
    },
    markupType: {
      get () {
        return this.$store.state.quote.form.markupType
      },
      set (nV) {
        this.$store.commit('quote/SET_FORM', {
          markupType: nV
        })
      }
    },
    markupAmount: {
      get () {
        return this.$store.state.quote.form.markupAmount
      },
      set (nV) {
        this.$store.commit('quote/SET_FORM', {
          markupAmount: nV
        })
      }
    },
    discountType: {
      get () {
        return this.$store.state.quote.form.discountType
      },
      set (nV) {
        this.$store.commit('quote/SET_FORM', {
          discountType: nV
        })
      }
    },
    discountAmount: {
      get () {
        return this.$store.state.quote.form.discountAmount
      },
      set (nV) {
        this.$store.commit('quote/SET_FORM', {
          discountAmount: nV
        })
      }
    },
    currency: {
      get () {
        return this.$store.state.quote.form.currency
      },
      set (nV) {
        this.$store.commit('quote/SET_FORM', {
          currency: nV
        })
      }
    },
    form () {
      return {
        customerName: this.customerName,
        markupType: this.markupType,
        markupAmount: this.markupAmount,
        discountAmount: this.discountAmount,
        currency: this.currency
      }
    }
  },
  watch: {
    '$route' (nV) {
      this.initPage()
    }
  },
  created () {
    this.initPage()
  },
  methods: {
    ...mapActions('quote', ['fetchData', 'fetchMore']),
    download () {
      this.$store.commit('SHOW_FULLSCREEN_LOADER')
      this.$store.dispatch('quote/download', {
        ...this.query,
        ...this.$store.state.quote.form
      }).then(({
        data,
        headers
      }) => {
        const newBlob = new Blob([data], { type: 'application/pdf' })

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
          // this.handleDownloadModalClosed()
          return
        }
        const tmp = window.URL.createObjectURL(newBlob)
        const contentDisposition = headers['content-disposition']
        const filename = contentDisposition.replace('attachment; filename=', '')
        let link = document.createElement('a')
        link.href = tmp
        link.download = filename
        link.click()
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(tmp)
        }, 100)
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
      }).catch((err) => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        if (err.meta) this.$store.commit('meta/SET_META_ACTION', err.meta)
        this.$store.commit('SET_GLOBAL_MODAL', {
          show: true,
          content: err.message || (err.error && err.error.message) || ''
        })
      })
    },
    currencyChange () {
      this.$router.push({
        name: 'quote',
        query: {
          ...this.query,
          ...{ currency: this.currency }
        }
      })
    },
    checkDate () {
      const dateNow = moment().format('YYYY-MM-DD')
      if (!this.query.checkInDate || !this.query.checkOutDate || this.query.checkInDate < dateNow || this.query.checkOutDate < dateNow) {
        this.$store.dispatch(
          'setGlobalErrorModal',
          {
            show: true,
            title: this.$t('app.error.past-date.title'),
            content: this.$t('app.error.past-date.content')
          }
        )
        return false
      } else {
        return true
      }
    },
    initPage () {
      this.$store.commit('quote/SET_FORM', {
        customerName: this.query.customerName ? this.query.customerName : '',
        markupType: this.query.markupType ? this.query.markupType : '',
        discountType: this.query.discountType ? this.query.discountType : '',
        markupAmount: this.query.markupAmount ? this.query.markupAmount : '',
        discountAmount: this.query.discountAmount ? this.query.discountAmount : '',
        currency: this.query.currency ? this.query.currency.toUpperCase() : ''
      })
      if (this.checkDate()) {
        this.fetchData({ ...this.query, limit: 3, page: 1, isLazy: true, isHotelQuote: true })
      }
    },
    handleMore (payload) {
      const tmp = this.list.find((item) => {
        const property = item.property || {}
        return property.propertyCode === payload.propertyCode
      })
      let { page } = tmp.availability.pagination
      this.fetchMore({ ...this.query, propertyCode: payload.propertyCode, page: ++page, limit: 3, isLazy: true, isHotelQuote: true })
    }
  },
  metaInfo () {
    return {
      title: 'Generate Quote | eBEDS'
    }
  }
}
</script>

<style scoped>
  .quote-info {
    width: 60%;
  }
</style>
