<template>
  <div>
    <div
      v-if="score"
      :class="['trustscore is-clearfix', toCss, cssSize, cssCondensed]">
      <div class="value">
        {{ rating }}
      </div>
      <div
        v-if="!lite"
        class="score-rating-container">
        <div class="score-wrapper">
          <div class="score">
            {{ $t(`common.${tier.key}`) }}
          </div>
          <div class="badges">
            <svg
              class="ty-icon-badges"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 66 24"> <g> <path
                fill="#119A11"
                d="M46.992 0v24l5.7-4.97h13.295V0" /> <path
                  fill="#FFA726"
                  d="M23.496 0v24l5.678-4.97h13.25V0" /> <path
                    fill="#F06748"
                    d="M0 0v24l5.703-4.97h13.305V0" /> </g></svg>
          </div>
        </div>
        <div class="rating-wrapper">
          <div class="trustyou-ui rating">
            <div class="rating-units">
              <span
                v-for="(item, idx) in starRating"
                :key="idx">
                <component :is="item.component" />
              </span>
            </div>
          </div>
          <div
            v-if="showReviewsCount"
            class="counter">
            {{ reviewsCount | number }}
            {{ $t('components.TrustYouRating.reviews') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tyGrades, tyToGrade } from '../../common'
import FullStar from './FullStar'
import HalfStar from './HalfStar'
import { round } from 'number-precision'

export default {
  name: 'TrustYouRating',
  components: {
    FullStar,
    HalfStar
  },
  props: {
    score: Number,
    reviewsCount: Number,
    /**
     * condensed
     * THe reviewscount can linebreak
     */
    condensed: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'm'
    },
    showReviewsCount: {
      type: Boolean,
      default: true
    },
    lite: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tier () {
      if (this.tierKey) {
        return tyGrades[this.tierKey]
      }
      return this.score && tyGrades[tyToGrade(this.score)]
    },
    cssCondensed () {
      return this.condensed && 'ty-condensed'
    },
    cssSize () {
      return `size-${this.size}`
    },
    toGrade () {
      return tyToGrade(this.score)
    },
    toCss () {
      return tyGrades[this.toGrade].css
    },
    toLabel () {
      return tyGrades[this.toGrade].label
    },
    rating () {
      const num = this.score / 20
      const decimalsCount = num.toString().length - (num.toString().indexOf('.') + 1)
      if (decimalsCount > 1) return round(num, 1)
      return num
    },
    starRating () {
      const rating = Number(this.rating)
      if (rating > 4.5) {
        return [
          { component: 'FullStar' },
          { component: 'FullStar' },
          { component: 'FullStar' },
          { component: 'FullStar' },
          { component: 'FullStar' }
        ]
      } else if (rating >= 4.3 && rating <= 4.5) {
        return [
          { component: 'FullStar' },
          { component: 'FullStar' },
          { component: 'FullStar' },
          { component: 'FullStar' },
          { component: 'HalfStar' }
        ]
      } else if (rating >= 4 && rating < 4.3) {
        return [
          { component: 'FullStar' },
          { component: 'FullStar' },
          { component: 'FullStar' },
          { component: 'FullStar' }
        ]
      } else if (rating >= 3.3 && rating < 4) {
        return [
          { component: 'FullStar' },
          { component: 'FullStar' },
          { component: 'FullStar' },
          { component: 'HalfStar' }
        ]
      } else if (rating >= 3 && rating < 3.3) {
        return [
          { component: 'FullStar' },
          { component: 'FullStar' },
          { component: 'FullStar' }
        ]
      } else if (rating > 2.3 && rating < 3) {
        return [
          { component: 'FullStar' },
          { component: 'FullStar' },
          { component: 'HalfStar' }
        ]
      } else if (rating >= 2 && rating < 2.3) {
        return [
          { component: 'FullStar' },
          { component: 'FullStar' }
        ]
      } else if (rating >= 1.3 && rating < 2) {
        return [
          { component: 'FullStar' },
          { component: 'HalfStar' }
        ]
      } else if (rating < 1.3) {
        return [{ component: 'FullStar' }]
      }
      return []
    }
  }
}
</script>
<style lang="scss" scoped>
.trustscore {
  position: relative;
  font-size: 14px;

  * {
    line-height: 1em;
  }

  &.size-s,
  &.size-m,
  &.size-l {
    * {
      box-sizing: border-box;
    }
  }

  &::after,
  &::before {
    display: table;
    clear: both;
    content: '';
  }

  &.size-m .value, &.size-s .value, &.size-xs .value {
    margin: 0 5px 0 0;
    padding: 11px 5px 0;
    font-weight: 500;
    font-size: 22px;
    width: 45px;
    height: 44px;
    text-align: center;
    float: none;
    display: inline-block;
    vertical-align: top;
  }

  &.size-s {
    .value {
      width: 33px;
      height: 32px;
      padding: 7px 2px 0;
      font-size: 19px;
    }

    .score-wrapper {
      margin-top: 0;
      display: inline-block;
    }

    .score {
      font-size: 19px;
      line-height: 15px;
      margin: 0 2px 0 0;
      font-weight: 500;
      float: left;
      color: #505050;
    }
  }

  .value {
    position: relative;
    float: left;
    background: #7cb342;
    color: #fff;

    &::after {
      position: absolute;
      top: 100%;
      left: 0;
      border-style: solid;
      border-color: #7cb342 transparent transparent #7cb342;
      content: '';
    }
  }

  .score-rating-container {
    display: inline-block;
    vertical-align: top;
  }
}

.trustscore.size-m .badges, .trustscore.size-s .badges, .trustscore.size-xs .badges {
  font-size: .56em;
  float: left;
  min-width: 25px;
}
.trustscore.size-m .badges .ty-icon, .trustscore.size-s .badges .ty-icon, .trustscore.size-xs .badges .ty-icon {
  margin-right: -2px;
}

.trustscore.size-s .rating-wrapper {
  display: block;
  margin-top: 0;
}

.trustscore.size-m .counter, .trustscore.size-s .counter, .trustscore.size-xs .counter {
  color: #999;
  font-weight: 500;
  font-size: 11px;
}
</style>
